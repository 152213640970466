import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";
export class BeforeFairPlay extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <Header />
        </div>
        <div>
          <main className="main mt-4">
            <div className="container">
              <h2 class="sectionTitle"> Fair Play</h2>
              <div className="fairplay terms-content">
                <h2>General Rules</h2>
                <p>
                  There are a few basic rules that every user should learn
                  before registering on royalcasino777.in. Since not everyone
                  wants to read the Terms and Conditions, study the following
                  points:
                </p>
                <ul>
                  <li>
                    • Only players over 18 years of age can be registered on the
                    site and admitted to the following activities;
                  </li>
                  <li>• Winnings are paid out maximum within 30 days;</li>
                  <li>
                    • If you participate in any sports competitions, you are not
                    allowed to bet on sports;
                  </li>
                  <li>• One player cannot have more than one account;</li>
                  <li>
                    • The administration of the bookmaker may at any time, if
                    necessary, request the personal data of the player to verify
                    the identity.
                  </li>
                </ul>
                <h2>Customer support </h2>
                <p>
                  With our 24-hour customer service, you can get answers to your
                  questions and solve your problem at any time. Contact us by
                  customer support number at any time of the day or night. The
                  platform is licensed by Curacao, which allows online
                  activities in the country. The site is designed to be safe and
                  user-friendly and offers the best gaming experience for
                  players. With simple steps, you can open an account, deposit
                  money and withdraw money with reliable and legal payment
                  methods at any time.
                </p>
                <h2>Territorial Restrictions</h2>

                <p>
                  According to this license, the company, which received it, has
                  the right to organize gambling and sports betting in all
                  countries except for the prohibited ones. The list of the
                  latter includes the United States, the Netherlands, France,
                  Curacao, and Australia. If you find a violation of this
                  requirement, users can complain to the regulatory commission.
                  To begin with, it is necessary to make sure that the company
                  does not operate in a particular country under a related
                  license.
                </p>
                <h2>Trademarks</h2>

                <p>
                  A number of the trademarks, trade names, and logos used on
                  this site are the intellectual property of the Curaçao Gaming
                  Commission and its subsidiaries. The only exceptions are the
                  domain and trade name of the company.
                </p>
                <h2>Fast Payments</h2>
                <p>
                  When you get your first winnings, you can withdraw them to
                  many electronic wallets and payment systems. Transactions are
                  carried out as quickly as possible – on average, within 6
                  hours.
                </p>
                <h2>Betting Odds</h2>
                <p>
                  The Royalcasino website uses a decimal odds system that allows
                  you to calculate your potential payout in just a few seconds.
                  To find out how many prizes you will get if your bet is
                  successful, multiply its amount by the odds.
                </p>
                <h2>Types of Bets</h2>
                <p>
                  A wide variety is also offered when selecting a specific bet
                  type. This choice can be made after you add specific odds to
                  the coupon and proceed to fill it out.
                </p>
                <p>
                  Single : Single bets are the easiest and safest. You choose
                  one of the events to which the corresponding odds are
                  assigned. The bet will play if the event you predicted
                  happens. In this case, you will receive a payout equal to the
                  amount of the bet multiplied by the odds.
                </p>
                <p>
                  Combo : Combination bets can be made on some events. They
                  include several outcomes, which must necessarily be related to
                  each other. This is analogous to an express, but bets can be
                  made only within the framework of interconnected events. For
                  example, in one match.
                </p>
                <h2>Match Results and Statistics</h2>

                <p>
                  The key to good and successful sports betting is a sufficient
                  amount of information and knowledge of the player, as well as
                  a bit of luck. The Royalcasino website offers players live
                  match statistics that can be used to draw conclusions about
                  certain match results. You don’t have to use additional
                  sources, because if you know a lot about sports, it will help
                  you make the right choice
                </p>
              </div>
            </div>
          </main>
        </div>
        <div>
          <BeforeLoginFooter />
        </div>
      </div>
    );
  }
}

export default BeforeFairPlay;
