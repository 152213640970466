export const EvolutionGames = {
  AutoRoulette: {
    href: "/casino/evolution/AutoRoulette",
    code: "1000123",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  thirtytwoCards: {
    href: "/casino/ezugi/thirtytwoCards",
    code: "228002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/card/casino057.png", alt: "" },
  },
  Baccarat: {
    href: "/casino/ezugi/Baccarat",
    code: "106",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/baccarat/casino034.png", alt: "" },
  },
  Blackjack: {
    href: "/casino/ezugi/Blackjack",
    code: "201",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  Megaball: {
    href: "/casino/evolution/Megaball",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  sicbodice01: {
    href: "/casino/ezugi/sicbodice01",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  CrazyTime: {
    href: "/casino/evolution/CrazyTime",
    code: "1000148",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DragonTiger1: {
    href: "/casino/evolution/DragonTiger1",
    code: "1000074",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DoubleBallRoulette: {
    href: "/casino/evolution/DoubleBallRoulette",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  InfiniteBlackjack: {
    href: "/casino/evolution/InfiniteBlackjack",
    code: "1000067",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratA: {
    href: "/casino/evolution/SpeedBaccaratA",
    code: "1000021",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  supersicbo: {
    href: "/casino/evolution/supersicbo",
    code: "1000110",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino060.png", alt: "" },
  },
  AmericanRoulette: {
    href: "/casino/evolution/AmericanRoulette",
    code: "1000011",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  RouletteVip: {
    href: "/casino/evolution/RouletteVip",
    code: "1000124",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/casino02.png", alt: "" },
  },
  DoubleBallRouletteLive: {
    href: "/casino/evolution/DoubleBallRouletteLive",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/casino070.PNG", alt: "" },
  },

  FootballStudio: {
    href: "/casino/evolution/FootballStudio",
    code: "1000112",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  CashorCrash: {
    href: "/casino/evolution/CashorCrash",
    code: "1000352",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  GonzoTreasureHunt: {
    href: "/casino/evolution/GonzoTreasureHunt",
    code: "1100022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DreamCatcher: {
    href: "/casino/evolution/DreamCatcher",
    code: "1000077",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
};
