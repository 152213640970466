import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";

import EzugiProvider from "../../../../assets/images/banner/Ez.png";
import EvolutionProvider from "../../../../assets/images/banner/EGaming.png";
import VivoProvider from "../../../../assets/images/banner/VivoGaming.png";
import QtechProvider from "../../../../assets/images/banner/QTech.png";
import worldcasino from "../../../../assets/images/banner/worldcasino.png";
import xpg from "../../../../assets/images/banner/xpg.png";
import supernowa from "../../../../assets/images/banner/supernowa.png";

import Footer from "../../../../containers/Footer";
// import EzugiProvider from "../../../../assets"
const AllCasino = () => {
  return (
    <>
      <HeaderAfterLogin />{" "}
      <main className="main mt-5" style={{ height: "100vh" }}>
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="container">
          <div className="game-heading mb-4">
            <h3>All Casino</h3>
          </div>
          <div>
            <div className="providerSection">
              <ul style={{ justifyContent: "flex-start" }}>
                <li>
                  <a href="/casino/ezugi">
                    <img src={EzugiProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution">
                    <img src={EvolutionProvider} />
                  </a>
                </li>
                <li>
                  <a href="/casino/supernowa">
                    <img src={supernowa} />
                  </a>
                </li>
                <li>
                  <a href="/casino/worldcasino">
                    <img src={worldcasino} />
                  </a>
                </li>
                <li>
                  <a href="/casino/xpg">
                    <img src={xpg} />
                  </a>
                </li>
                {/* <li>
                  <a href="/casino/vivo">
                    <img src={VivoProvider} />
                  </a>
                </li> */}
                {/* <li>
                  <a href="/casino/qtech">
                    <img src={QtechProvider} />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default AllCasino;
