import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class GamesRule extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <div>
          <main className="main mt-4">
            <div className="container terms-content">
              <h2 class="sectionTitle"> Games Rules</h2>
              <p>
                Once all session/fancy bets are completed and settled there will
                be no reversal even if the Match is Tied or is Abandoned.
              </p>
              <p>
                Advance Session or Player Runs and all Fancy Bets are only valid
                for 20/50 overs full match each side. (Please Note this
                condition is applied only in case of Advance Fancy Bets only).
              </p>
              <p>
                All advance fancy bets market will be suspended 60 mins prior to
                match and will be settled.
              </p>
              <p>
                Under the rules of Session/Fancy Bets if a market gets Suspended
                for any reason whatsoever and does not resume then all previous
                Bets will remain Valid and become HAAR/JEET bets.
              </p>
              <p>
                Incomplete Session/Fancy Bet will be cancelled but Complete
                Session will be settled.
              </p>
              <p>
                In the case of Running Match getting Cancelled/ No Result/
                Abandoned but the session is complete it will still be settled.
                Player runs / fall of wicket will be also settled at the figures
                where match gets stopped due to rain for the inning (D/L) ,
                cancelled , abandoned , no result.
              </p>
              <p>
                If a player gets Retired Hurt and one ball is completed after
                you place your bets then all the betting till then is and will
                remain valid.
              </p>
              <p>
                Should a Technical Glitch in Software occur, we will not be held
                responsible for any losses.
              </p>
              <p>
                Should there be a power failure or a problem with the Internet
                connection at our end and session/fancy market does not get
                suspended then our decision on the outcome is final.
              </p>
              <p>
                Should there be a power failure or a problem with the Internet
                connection at our end and session/fancy market does not get
                suspended then our decision on the outcome is final.
              </p>
              <p>
                Any bets which are deemed of being suspicious, including bets
                which have been placed from the stadium or from a source at the
                stadium maybe voided at anytime. The decision of whether to void
                the particular bet in question or to void the entire market will
                remain at the discretion of Company. The final decision of
                whether bets are suspicious will be taken by Company and that
                decision will be full and final.
              </p>
              <p>
                Any sort of cheating bet , any sort of Matching (Passing of
                funds), Court Siding (Ghaobaazi on commentary), Sharpening,
                Commission making is not allowed in Company, If any company User
                is caught in any of such act then all the funds belonging that
                account would be seized and confiscated. No argument or claim in
                that context would be entertained and the decision made by
                company management will stand as final authority.
              </p>
              <p>
                Fluke hunting/Seeking is prohibited in Company , All the fluke
                bets will be reversed. Cricket commentary is just an additional
                feature and facility for company user but company is not
                responsible for any delay or mistake in commentary.
              </p>
              <p>Valid for only 1st inning.</p>
              <ul>
                <li>
                  • Highest Inning Run :- This fancy is valid only for first
                  inning of the match.{" "}
                </li>
                <li>
                  • Lowest Inning Run :- This fancy is valid only for first
                  inning of the match.
                </li>
              </ul>
              <p>
                If any fancy value gets passed, we will settle that market after
                that match gets over. For example :- If any market value is (
                22-24 ) and incase the result is 23 than that market will be
                continued, but if the result is 24 or above then we will settle
                that market. This rule is for the following market.
              </p>
              <ul>
                <li>• Total Sixes In Single Match </li>
                <li>• Total Fours In Single Match </li>
                <li>• Highest Inning Run </li>
                <li>• Highest Over Run In Single Match </li>
                <li>• Highest Individual Score By Batsman </li>
                <li>• Highest Individual Wickets By Bowler</li>
              </ul>
              <p>
                If any fancy value gets passed, we will settle that market after
                that match gets over. For example :- If any market value is (
                22-24 ) and incase the result is 23 than that market will be
                continued, but if the result is 22 or below then we will settle
                that market. This rule is for the following market.{" "}
              </p>
              <ul>
                <li>• Lowest Inning Run </li>
                <li>• Fastest Fifty </li>
                <li>• Fastest Century</li>
              </ul>
              <p>
                If any case wrong rate has been given in fancy ,that particular
                bets will be cancelled (Wrong Commentary).{" "}
              </p>
              <p>
                In case customer make bets in wrong fancy we are not liable to
                delete, no changes will be made and bets will be considered as
                confirm bet.
              </p>
              <ul>
                <li>Dot Ball Market Rules Wides Ball - Not Count </li>
                <li>No Ball - Not Count </li>
                <li>Leg Bye - Not Count as A Dot Ball </li>
                <li>Bye Run - Not Count as A Dot Ball </li>
                <li>Run Out - On 1st Run Count as A Dot Ball </li>
                <li>Run Out - On 2nd n 3rd Run Not Count as a Dot Ball </li>
                <li>
                  Out - Catch Out, Bowled, Stumped n LBW Count as A Dot Ball{" "}
                </li>
              </ul>
              <p>Bookmaker Rules </p>
              <ul>
                <li>
                  • Due to any reason any team will be getting advantage or
                  disadvantage we are not concerned.{" "}
                </li>
                <li>
                  • We will simply compare both teams 25 overs score higher
                  score team will be declared winner in ODI.{" "}
                </li>
                <li>
                  • We will simply compare both teams 10 overs higher score team
                  will be declared winner in T20 matches.
                </li>
              </ul>
              <p>
                Penalty Runs - Any Penalty Runs Awarded in the Match (In Any
                Running Fancy or ADV Fancy) Will Not be Counted While Settling
                in our Exchange.{" "}
              </p>
              <h2>Comparison Market</h2>
              <p>
                In Comparison Market We Don't Consider Tie or Equal Runs on Both
                the Innings While Settling . Second Batting Team Must need to
                Surpass 1st Batting's team Total to win otherwise on Equal Score
                or Below We declare 1st Batting Team as Winner .{" "}
              </p>
              <p>
                Player Boundaries Fancy:- We will only consider Direct Fours and
                Sixes hit by BAT.{" "}
              </p>
              <p>BOWLER RUN SESSION RULE :-</p>
              <ul>
                <li>
                  IF BOWLER BOWL 1.1 OVER,THEN VALID ( FOR BOWLER 2 OVER RUNS
                  SESSION ){" "}
                </li>
                <li>
                  IF BOWLER BOWL 2.1 OVER,THEN VALID ( FOR BOWLER 3 OVER RUNS
                  SESSION ){" "}
                </li>
                <li>
                  IF BOWLER BOWL 3.1 OVER,THEN VALID ( FOR BOWLER 4 OVER RUNS
                  SESSION ){" "}
                </li>
                <li>
                  IF BOWLER BOWL 4.1 OVER,THEN VALID ( FOR BOWLER 5 OVER RUNS
                  SESSION ){" "}
                </li>
                <li>
                  IF BOWLER BOWL 9.1 OVER,THEN VALID ( FOR BOWLER 10 OVER RUNS
                  SESSION ){" "}
                </li>
                <li>
                  Total Match Playing Over ADV :- We Will Settle this Market
                  after Whole Match gets Completed
                </li>
              </ul>
              <p>
                Criteria :- We Will Count Only Round- Off Over For Both the
                Innings While Settling (For Ex :- If 1st Batting team gets all
                out at 17.3 , 18.4 or 19.5 we Will Count Such Overs as 17, 18
                and 19 Respectively and if Match gets Ended at 17.2 , 18.3 or
                19.3 Overs then we will Count that as 17 , 18 and 19 Over
                Respectively... and this Will Remain Same For Both the Innings
                ..{" "}
              </p>
              <p>
                In Case Of Rain or if Over gets Reduced then this Market will
                get Voided{" "}
              </p>
              <p>
                We Will Settle this Market after Whole Match gets Completed .
              </p>
              <p>
                In Case Of Rain or if Over Gets Reduced then this Market Will
                get Voided{" "}
              </p>
              <h2>KHADDA :-</h2>
              <p>
                ADV Khadda Fancy is Valid Only for First Innings of the Match{" "}
              </p>
              <p>
                In Case of Rain or If Over Gets Reduced then this Market Will
                get Voided{" "}
              </p>
              <p>
                Incomplete Session Bet will be Cancelled but Complete Session
                Will be Settled
              </p>
              <h2>LOTTERY :- </h2>
              <p>
                In Case of Rain or If Over Gets Reduced then this Market Will
                get Voided{" "}
              </p>
              <p>
                Incomplete Session Bet will be Cancelled but Complete Session
                Will be Settled
              </p>
              <p>
                Criteria :- We will Only Count Last Digit of Sessions Total
                while settling ..For Example if in 6 Overs Market the Score is
                37 ...so we will Settle the Market for 6 Over Lottery @ 7{" "}
              </p>
              <p>
                Any cricket event which is being held behind closed doors in
                that if any players found to be taking advantage of groundline
                in fancy bets in such cases bets can be voided after the market
                ends . Company decision to be final .{" "}
              </p>
              <h2>IPL RULES :-</h2>
              <p>
                If Over Reduced in Match, we will not count the actual scores of
                the Over Reduced Matches instead we will count the Market’s
                Average Scores.{" "}
              </p>
              <p>
                If Match is Abandoned, we will not count the actual scores of
                the Abandoned Matches instead we will count the Market’s Average
                Scores.
              </p>

              <h4>
                NOTE: -. These rules are for the following Markets of ENTIRE IPL
                2021 (60 Matches): -{" "}
              </h4>
              <p>
                Total Match 1st Over Run :- Average 6 Runs will be given if the
                match is abandoned or over reduced, But If 1st Over is Completed
                then it will not be counted as Average Score instead we will
                count the Actual Score if Over is Reduced after completion of
                1st Over.{" "}
              </p>
              <ul>
                <li>
                  Total Fours: - Average 26 Fours will be given if the match is
                  abandoned or over reduced.
                </li>
                <li>
                  Total Sixes: - Average 13 Sixes will be given if the match is
                  abandoned or over reduced.
                </li>
                <li>
                  Total Wide: - Average 8 Wide’s will be given if the match is
                  abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Extras: - Average 14 Extras will be given if the match
                  is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total No Ball: - Average 1 No Ball will be given if the match
                  is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Duck: - Average 1 Duck will be given if the match is
                  abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Fifties: - Average 2 Fifties will be given if the match
                  is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Century: -Average 0 Century will be given if the match
                  is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Run Out: - Average 1 Run Out will be given if the match
                  is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Wickets: - Average 12 Wickets will be given if the match
                  is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Caught out: - Average 8 Caught Out will be given if the
                  match is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Maiden Over: - Average 0 Maiden Over will be given if
                  the match is abandoned or over reduced.{" "}
                </li>
                <li>
                  Total LBW: - Average 1 LBW will be given if the match is
                  abandoned or over reduced.{" "}
                </li>
                <li>
                  Total Bowled: - Average 2 Bowled will be given if the match is
                  abandoned or over reduced.
                </li>
              </ul>
              <p>
                • In case IPL Matches Gets Stopped or Interrupted Due to
                "COVID-19" or Any "ACT OF GOD" Reasons, Under 45 Matches then
                All IPL 2021 Tournament Fancy Markets will be Voided, But if 45
                or Above Matches are Being Played then we will Settle all the
                Fancy Markets and Also If there are NON Played Matches Above 45
                then we will settle the markets as per our Markets Average.{" "}
              </p>
              <p>
                * If IPL 2021 gets stopped due to “Covid-19” or Any “Act of God”
                reason then We will wait till 45 days from the day IPL gets
                stopped , and if IPL Matches gets Resumed before 45th day then
                we will continue as Usual Score Countation but if IPL 2021 does
                not resumes until 45th day then we will settle the markets
                according to our above mentioned Markets Averages and In case of
                Void, We will Void the under 45 matches on 45th day from the day
                IPL 2021 stopped.{" "}
              </p>
              <p>
                <h2>Session Odd-Even Rule:-</h2> We Will Settle the Session
                ODD-EVEN Market only if the Over is Completed, But If that Over
                is Not Completed then we will Void that “Session Total Odd”
                Marke
              </p>
            </div>
          </main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default GamesRule;
